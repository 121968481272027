import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { SignUpContextProviderProps,SignUpContextType } from '../interfaces/ContextInterface';
import { TEXT_MESSAGES } from "../const"
const SignUpContext = createContext<SignUpContextType | undefined>(undefined);
const SignUpContextProvider: React.FC<SignUpContextProviderProps> = ({ children }) => {
  const [showSignButton, setShowSignButton] = useState(false);
  const [id, setUserId] = useState('');
  const [emailId,setEmailId] = useState('');
return (
    <SignUpContext.Provider value={{ showSignButton, setShowSignButton,id,setUserId,emailId,setEmailId }}>
      {children}
    </SignUpContext.Provider>
  );
};
const useSignUpContext = () => {
  const context = useContext(SignUpContext);
 if (!context) {
    throw new Error(TEXT_MESSAGES.USE_SIGNUP);
  }
return context;
};
export { SignUpContextProvider, useSignUpContext };