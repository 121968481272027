import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
interface SidebarContextProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}
interface SidebarProviderProps {
  children: ReactNode;
}
const SidebarContext = createContext<SidebarContextProps>({
  isOpen: true,
  toggleSidebar: () => {},
});
export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(() => {
    const savedIsOpen = localStorage.getItem('issidebarOpen');
    return savedIsOpen ? JSON.parse(savedIsOpen) : true;
  });

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    localStorage.setItem('issidebarOpen', JSON.stringify(isOpen));
  }, [isOpen]);

  return (
    <SidebarContext.Provider value={{ isOpen, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
export const useSidebar = () => useContext(SidebarContext);