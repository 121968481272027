import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { Children } from 'react';
import PheonixMenuProps from '../interfaces/PheonixMenuProps';

const PheonixMenu: React.FC<PheonixMenuProps> = ({ open, onClose, id,anchorEl,children,transformOrigin   }) => {
 return (
      <Menu
        id={id}
        open={open}
        onClose={onClose}  
        anchorEl={anchorEl}
        transformOrigin ={transformOrigin }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
   {React.Children.map(children, (child) =>
        React.isValidElement<PheonixMenuProps>(child) ? React.cloneElement(child) : null
      )}
      </Menu>
    );
  };
  export default PheonixMenu;