import React, { ChangeEvent, FocusEvent, ReactNode } from "react";
import TextField from "@mui/material/TextField";
import PheonixTextFieldProps from "../interfaces/PheonixTextFieldProps";

const PheonixTextField: React.FC<PheonixTextFieldProps> = ({
  id,
  type,
  value,
  placeholder,
  label,
  variant,
  disabled,
  onChange,
  name,
  onFocus,
  onBlur,
  endAdornment,
  style,
  required,
  multiline,
  rows,
  rememberMe,
  className,
  error,
  size,
  helperText,
  readonly,
}) => {
  return (
    <TextField
      id={id}
      type={type}
      value={value}
      name={name}
      placeholder={placeholder}
      label={label}
      variant={variant}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      size={size}
      helperText={helperText}
      InputProps={{
        endAdornment: endAdornment,
        className: className,
        style: style,
        readOnly:readonly,
      }}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      required={required}
      sx={{
        "& label.Mui-focused": {
          color: "#181D8C",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#181D8C",
        },
        "& .MuiInputLabel-root": {
          color: "#032C43",
        },
        "& .MuiInputLabel-outlined": {
          color: "#032C43",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#181D8C!important",
          },
        },
        "& .MuiInputBase-root": {
      backgroundColor: "white", 
    },
      }}
    />
  );
};
export default PheonixTextField;
