import React, { useState } from 'react';
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { TEXT_MESSAGES } from "../const"
const Dashboard: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
      <div 
          style={{ 
               height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
          }}
      >
          <Typography
              style={{
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  fontSize: isSmallScreen ? "32px" : "42px",
                  color: "black",
                  textAlign: "center"
              }}
          >
              {TEXT_MESSAGES.hometext}
          </Typography>
      </div>
  );

};
export default Dashboard;