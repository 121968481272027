import React from 'react';
import Box from '@mui/material/Box';
import { PheonixBoxProps } from '../interfaces/PheonixBoxProps';

const PheonixBox : React.FC<PheonixBoxProps>=({component,children})=>{
 return(
        <Box
        component={component}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
             {children}
        </Box>
    );
};
export default PheonixBox;