import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../Components/PheonixDeleteModal";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import PheonixSnackBar from "../../Components/PheonixSnackBar";
import axios from "axios";
import Invoicemock from "../../interfaces/Invoice";
import PheonixPaper from "../../Components/PheonixPaper";
import Popover from "@mui/material/Popover";
import PheonixButton from "../../Components/PheonixButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/logo.jpg";
import { Typography, Box, Button, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSignUpContext } from "../../Contexts/SignUpContext";
import PheonixDataGrid from "../../Components/PheonixDataGrid";
import PheonixChip from "../../Components/PheonixChip";
import PheonixModal from "../Quotation/PheonixModal";
import { TEXT_MESSAGES } from "../../const";
import { useQuery } from "@apollo/client";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PheonixBreadcrumbs from "../../Components/PheonixBreadcrumbs";
import { pdfdata } from "../../const";
import { GET_ALL_CUSTOMERS } from "../../graphql/query";
import { GET_INVOICE_QUERY, GET_INVOICE_WITH_TYPE,GET_INVOICE_WITH_TYPEBYDRAFT } from "../../graphql/query";
import { DELETE_INVOICE } from "../../graphql/mutation";
import { ProductOrService, RowData, CustomerOption } from "./InvoiceInterface";
import InvoiceModal from "./InvoiceModal";

const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
  customerNo: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
  currency: "",
};

const Invoicescreen: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [invoicessData, setInvoicesData] = useState<Invoicemock[]>([]);
  const [viewData, setViewData] = useState<RowData[]>([]);
  const [filteredInvoicesData, setFilteredInvoicesData] = useState<
    Invoicemock[]
  >([]);
  const [error, setError] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [dataLoading, setdataLoading] = useState(false);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const [customerData, setCutomerData] = useState<CustomerOption>();
  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);

  const {
    loading: invoiceLoading,
    error: invoiceError,
    data: invoiceData,
    refetch: invoiceRefetch,
  } = useQuery(GET_INVOICE_WITH_TYPEBYDRAFT, {
    variables: {
      customer_id: userId,
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, invoices } = data.getInvoicebyUserByDraft;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;

      const responseDataWithIndex = invoices.map((item: {}, index: number) => ({
        ...item,
        serial_no: (startIndex + index).toString(),
      }));
      setdataLoading(false);

      setInvoicesData(responseDataWithIndex);
      setTotalPages(totalCount);
    },
  });

  useEffect(() => {
    if (!invoiceLoading && !invoiceError && invoiceData) {
      const { totalPages, totalCount, invoices } =
        invoiceData.getInvoicebyUserByDraft;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const responseDataWithIndex = invoices.map((item: {}, index: number) => ({
        ...item,
        serial_no: (startIndex + index).toString(),
      }));

      setInvoicesData(responseDataWithIndex);
      setTotalPages(totalCount);

      setdataLoading(false);
    }
  }, [
    invoiceData,
    invoiceLoading,
    invoiceError,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      invoiceRefetch({ variables: { searchQuery: {} } });
    } else {
      invoiceRefetch();
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const handleDeleteClick = (id: string, name: string) => {
    setEntityToDelete(id);
    setEntityToDisplay(name);
    setDeleteModalOpen(true);
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;
    const token = localStorage.getItem("token");
    const query = DELETE_INVOICE;
    const variables = {
      id: entityToDelete,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { success, message } = response.data.data.deleteInvoice;
      if (success) {
        setSuccMessage(TEXT_MESSAGES.INVOICE_DELETE_SUCCESS);
        setDeleteModalOpen(false);
      } else {
        setSuccMessage(TEXT_MESSAGES.INVOICE_DELETE_FAIL);
      }
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        invoiceRefetch();
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setdataLoading(true);
    invoiceRefetch();
  }, []);

  const fetchCustomers = async () => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_CUSTOMERS,
        },
        config
      );
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no: string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: {
                address_line1: string;
                city: string;
                state: string;
                pincode: string;
                country: string;
              };
            };
            billing_info: { GST_no: string; currency: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            customerNo: customer.customer_no,
            city: customer.basic_info.shipping_address.city,
            state: customer.basic_info.shipping_address.state,
            pincode: customer.basic_info.shipping_address.pincode,
            country: customer.basic_info.shipping_address.country,
            currency: customer.billing_info.currency,
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const getStatusColorAndIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case "paid":
        return { color: "#05CD31", Icon: CheckCircleIcon };
      case "unpaid":
        return { color: "#9F9F9F", Icon: CancelIcon };
      case "partially paid":
        return { color: "#FC891F", Icon: CheckCircleIcon };
      case "cancelled":
        return { color: "#FD3232", Icon: CancelIcon };
      case "overdue":
        return { color: "#CD0505", Icon: ErrorOutlineOutlinedIcon };
      default:
        return { color: "#000000", Icon: CheckCircleIcon };
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleOpen = () => {
    navigate("/invoicenew");
  };

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const imageUrl = logo;

  const mapRowsToProductOrService = (rows: RowData[]): ProductOrService[] => {
    return rows.map((row) => ({
      name: row.name || null,
      tax: row.tax || null,
      amount: row.amount ? Number(row.amount) : null,
      rate: row.rate ? Number(row.rate) : null,
      quantity: row.quantity ? Number(row.quantity) : null,
      productplan: row.productplan || null,
      billcycle: row.billcycle || null,
      hsnCode: row.hsncode || null,
    }));
  };

  const getCurrencySymbol1 = (currency: any) => {
    switch (currency) {
      case "Rupees":
        return "₹";
      case "Dollars":
        return "$";
      case "Euro":
        return "€";
      default:
        return "₹";
    }
  };

  const getCurrencySymbol = (currency: any) => {
    switch (currency) {
      case "Rupees":
        return "Rs.";
      case "Dollars":
        return "$";
      case "Euro":
        return "€";
      default:
        return "₹";
    }
  };

 
  const handleDownloadPDF = (rowData: any) => {

    const productOrService = mapRowsToProductOrService(rowData.productOrService);
    const doc = new jsPDF() as any;
    doc.addImage(imageUrl, "JPEG", 15, 10, 50, 35);
    doc.setFontSize(40);
    doc.setFont("helvetica", "bold");
    const text = rowData.currency === "Rupees" ? "Invoice" : "Export Invoice";
    const xPosition = text === "Invoice" ? 145 : 98;
    doc.text(text, xPosition, 20);
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginRight = 15;
    const labelMarginLeft = 14;

    const rightAlignTextWithColors = (
      label: any,
      value: any,
      labelColor: any,
      valueColor: any,
      yPosition: any,
      applyLabelMarginLeft: any
    ) => {
      const labelWidth =
        (doc.getStringUnitWidth(label) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const valueWidth =
        (doc.getStringUnitWidth(value) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const totalWidth = labelWidth + valueWidth;

      const xLabel =
        pageWidth -
        totalWidth -
        marginRight -
        (applyLabelMarginLeft ? labelMarginLeft : 0);
      const xValue =
        xLabel + labelWidth + (applyLabelMarginLeft ? labelMarginLeft : 0);

      doc.setTextColor(labelColor);
      doc.text(label, xLabel, yPosition);

      doc.setTextColor(valueColor);
      doc.text(value, xValue, yPosition);
    };
    const safeText = (text: any) => (text ? text.toString() : "");

    const texts = [
      {
        label: "Invoice ID : ",
        value: rowData.invoiceid,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
      {
        label: "Invoice Date : ",
        value: rowData.invoicedate,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
      {
        label: "Valid Till : ",
        value: rowData.duedate,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
    ];

    const yPositions = [28, 35, 42];
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    texts.forEach((text, index) => {
      rightAlignTextWithColors(
        text.label,
        text.value,
        text.labelColor,
        text.valueColor,
        yPositions[index],
        text.applyLabelMarginLeft
      );
    });

    doc.setTextColor("#808080");
    doc.text("From ", 15, 60);
    doc.setTextColor("#000000");
    const pheonixAddress = pdfdata.PHEONIX_ADDRESS;
    doc.text(pheonixAddress, 12, 65);

    const text1 = "To ";
    let yPosition = 60;
    doc.setTextColor("#808080");
    const minX = 120;
    const calculateTextWidth = (text: any) =>
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    const x1 = Math.max(
      pageWidth - calculateTextWidth(text1) - marginRight,
      minX
    );

    doc.text(text1, x1, yPosition);
    doc.setTextColor("#000000");

    const customerDetails = customerOptions.find(
      (customer) => customer.id === rowData.customer_id
    );

    const availableDetails = [
      {
        order: 1,
        label: "",
        text: "Name",
        value: safeText(customerDetails?.name),
      },
      {
        order: 2,
        label: "",
        text: "Email",
        value: safeText(customerDetails?.email),
      },
      {
        order: 4,
        label: "",
        text: "City",
        value: safeText(customerDetails?.city),
      },
      {
        order: 3,
        label: "",
        text: "Address",
        value: safeText(customerDetails?.address),
      },
      {
        order: 5,
        label: "",
        text: "State",
        value: safeText(customerDetails?.state),
      },
      {
        order: 6,
        label: "",
        text: "Pincode",
        value: safeText(customerDetails?.pincode),
      },
      {
        order: 7,
        label: "",
        text: "Phone",
        value: safeText(customerDetails?.number),
      },
      {
        order: 8,
        label: "GSTIN:",
        text: "GST No",
        value: safeText(customerDetails?.gst_no),
      },
    ].filter((detail) => detail.value);

    const sortedDetails = availableDetails.sort((a, b) => a.order - b.order);

    sortedDetails.forEach((detail, index) => {
      const textToDisplay = detail.label
        ? detail.label + detail.value
        : detail.value;

      const x = Math.max(
        pageWidth - calculateTextWidth(textToDisplay) - marginRight,
        minX
      );

      doc.setTextColor("#000000");

      if (detail.text === "Address") {
        const addressLines = detail.value.split("\n");
        addressLines.forEach((line: any) => {
          const wrappedLine = doc.splitTextToSize(
            line,
            pageWidth - minX - marginRight
          );
          wrappedLine.forEach((linePart: any) => {
            doc.text(linePart, pageWidth - marginRight, (yPosition += 5), {
              align: "right",
            });
          });
        });
      } else {
        doc.text(textToDisplay, x, (yPosition += 5));
      }
    });
    const products = productOrService.map((service: any) => ({
      name: service.name,
      amount: service.amount,
      productplan: service.productplan,
      tax: service.tax,
      quantity: service.quantity,
    }));
    const subtotal = products.reduce(
      (sum, product) => sum + parseFloat(product.amount),
      0
    );
    const currencydata = rowData.currency;

    const calculateProductTaxes = () => {
      const gstNumber = customerDetails?.gst_no || "";

      let cgst = 0;
      let sgst = 0;
      let igst = 0;

      const productsWithTax = products.map((product) => {
        const productAmount = parseFloat(product.amount);
        let productCgst = 0;
        let productSgst = 0;
        let productIgst = 0;
        
        if (product.tax === "Non-Taxable") {
          productCgst = 0;
          productSgst = 0;
          productIgst = 0;
        } else {
          if (currencydata === "Rupees") {
            if (!gstNumber) {
              const taxRate = 9;
              productCgst = (productAmount * taxRate) / 100;
              productSgst = (productAmount * taxRate) / 100;
              cgst += productCgst;
              sgst += productSgst;
            } else if (gstNumber.startsWith("33")) {
              const taxRate = 9;
              productCgst = (productAmount * taxRate) / 100;
              productSgst = (productAmount * taxRate) / 100;
              cgst += productCgst;
              sgst += productSgst;
            } else {
              const taxRate = 18;
              productIgst = (productAmount * taxRate) / 100;
              igst += productIgst;
            }
          }
        }

        return {
          ...product,
          productCgst,
          productSgst,
          productIgst,
          totalProductTax: productCgst + productSgst + productIgst,
        };
      });

      return {
        productsWithTax,
        totalCgst: cgst,
        totalSgst: sgst,
        totalIgst: igst,
      };
    };

    const taxCalculation = calculateProductTaxes();

    const currencysymbl = getCurrencySymbol(currencydata);

    const bodyData = products.map((name) => [
      name.name,
      name.productplan,
      name.quantity,
      `${currencysymbl} ${name.amount}`,
    ]);

    let startY = 130;
    const customStyles = {
      fillColor: [255, 255, 255],
      lineHeight: 50,
      cellPadding: 2,
      textColor: [0, 0, 0],
      fontSize: 12,
      lineWidth: { bottom: 0.1 },
      lineColor: [150, 150, 150],
    };

    doc.autoTable({
      startY,
      head: [["Product/Service", "Product Plan", "Quantity", "Price"]],
      body: bodyData,
      styles: customStyles,
      theme: "plain",
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: { bottom: 0.1 },
        lineColor: [150, 150, 150],
      },
      bodyStyles: {
        lineWidth: { bottom: 0.1 },
        lineColor: [150, 150, 150],
      },
    });
    const tableBody = [];

    tableBody.push([
      { content: "Subtotal:", styles: { halign: "right" } },
      {
        content: `${currencysymbl} ${subtotal.toFixed(2)}`,
        styles: { halign: "left" },
      },
    ]);

    if (taxCalculation.totalCgst > 0 || taxCalculation.totalSgst > 0) {
      tableBody.push([
        { content: "CGST 9%:", styles: { halign: "right" } },
        {
          content: `${currencysymbl} ${taxCalculation.totalCgst.toFixed(2)}`,
          styles: { halign: "left" },
        },
      ]);
      tableBody.push([
        { content: "SGST 9%:", styles: { halign: "right" } },
        {
          content: `${currencysymbl} ${taxCalculation.totalSgst.toFixed(2)}`,
          styles: { halign: "left" },
        },
      ]);
    } else if (taxCalculation.totalIgst > 0) {
      tableBody.push([
        { content: "IGST 18%:", styles: { halign: "right" } },
        {
          content: `${currencysymbl} ${taxCalculation.totalIgst.toFixed(2)}`,
          styles: { halign: "left" },
        },
      ]);
    }

    if (rowData.discount) {
      let discountText =
        rowData.discountType === "%"
          ? `Discount (${rowData.discount}%):`
          : `Discount:`;

      let discountValue =
        rowData.discountType === "%"
          ? `${currencysymbl} ${((subtotal * rowData.discount) / 100).toFixed(
              2
            )}`
          : `${currencysymbl} ${rowData.discount}`;

      tableBody.push([
        { content: discountText, styles: { halign: "right" } },
        { content: discountValue, styles: { halign: "left" } },
      ]);
    }

    let discountAmount =
      rowData.discountType === "%"
        ? (subtotal * rowData.discount) / 100
        : rowData.discount;
    let totalAfterDiscount =
      subtotal -
      discountAmount +
      taxCalculation.totalCgst +
      taxCalculation.totalSgst +
      taxCalculation.totalIgst;

    tableBody.push([
      { content: "Total:", styles: { halign: "right", fontStyle: "bold" } },
      {
        content: `${currencysymbl} ${totalAfterDiscount.toFixed(2)}`,
        styles: { halign: "left", fontStyle: "bold" },
      },
    ]);

    yPosition = doc.autoTable.previous.finalY + 5;

    doc.autoTable({
      body: tableBody,
      startY: yPosition,
      theme: "plain",
      tableWidth: 60,
      styles: {
        font: "helvetica",
        fontSize: 12,
        cellPadding: 2,
        lineColor: [150, 150, 150],
        lineWidth: {
          bottom: 0.05,
          top: 0,
          left: 0,
          right: 0,
        },
      },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
      },
      margin: {   left: currencysymbl !== 'Rs.' ? 135 : 137 },
    });
    yPosition = doc.autoTable.previous.finalY + 10;
    const quotationNotesTitle = "Quotation Notes:";
    const quotationNotesContent = rowData.quotationnotes || "None";
    const customerNotesTitle = "Customer Notes:";
    const customerNotesContent = rowData.customernotes || "None";

    const maxWidth = 180;
    const addTextWithPagination = (
      doc: jsPDF,
      title: string,
      content: string,
      startY: number,
      showTitleOnFirstPage: boolean = true
    ) => {
      if (!content || content.trim() === "None") {
        return startY;
      }

      let contentYPosition = startY;
      const maxWidth = 180;
      const lines = doc.splitTextToSize(content, maxWidth);
      const pageHeight = doc.internal.pageSize.height;
      const bottomMargin = 20;
      const topMargin = 30;

      let titleDisplayed = !showTitleOnFirstPage;
      let isFirstPage = true;

      for (const line of lines) {
        if (contentYPosition + 20 > pageHeight - bottomMargin) {
          doc.addPage();
          contentYPosition = topMargin;
          isFirstPage = false;

          if (showTitleOnFirstPage && !titleDisplayed) {
            doc.setFontSize(12);
            doc.text(title, 20, contentYPosition);

            doc.setFontSize(11);
            contentYPosition += 10;
            titleDisplayed = true;
          }
        }

        if (!titleDisplayed && showTitleOnFirstPage) {
          doc.setFontSize(12);
          doc.text(title, 20, contentYPosition);

          doc.setFontSize(11);
          contentYPosition += 10;
          titleDisplayed = true;
        }

        doc.setFontSize(11);
        doc.text(line, 20, contentYPosition);
        contentYPosition += 10;
      }

      return contentYPosition;
    };
    doc.setFontSize(11);
    yPosition = addTextWithPagination(
      doc,
      quotationNotesTitle,
      quotationNotesContent,
      yPosition,
      true
    );
    yPosition += 10;
    yPosition = addTextWithPagination(
      doc,
      customerNotesTitle,
      customerNotesContent,
      yPosition,
      true
    );

    yPosition += 10;
    const termsTitle = "Terms and Conditions:";
    const termsContent = rowData.terms_conditions || "None";

    if (termsContent && termsContent.trim() !== "None") {
      const termsLines = doc.splitTextToSize(termsContent, 180);
      const estimatedTermsHeight = termsLines.length * 10 + 15;

      if (
        yPosition + estimatedTermsHeight >
        doc.internal.pageSize.height - 40
      ) {
        doc.addPage();
        yPosition = 30;
      }

      yPosition = addTextWithPagination(
        doc,
        termsTitle,
        termsContent,
        yPosition,
        true
      );
    }
    const currentYear = new Date().getFullYear();
    const pageHeight = doc.internal.pageSize.getHeight();

    const addFooter = () => {
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(11);
        const policyLinks = TEXT_MESSAGES.PDF_TEXT;
        doc.text(policyLinks, pageWidth - 10, pageHeight - 25, {
          align: "right",
        });
        doc.setLineWidth(0.5);
        doc.setDrawColor(200, 200, 200);
        const lineY = pageHeight - 20;
        doc.line(10, lineY, pageWidth - 10, lineY);

        doc.setFontSize(12);
        doc.setTextColor(33, 33, 33);
        doc.setFont("helvetica", "normal");
        const copyrightText = `©${currentYear} ${TEXT_MESSAGES.PDF_COPY}`;

        doc.text(copyrightText, 10, pageHeight - 10);
      }
    };

    addFooter();
    doc.save(`${rowData.invoiceid}.pdf`);
  };

  const [userType, setUserType] = useState<number | null>(null);
  useEffect(() => {
    const storedUserType = localStorage.getItem("usertype");
    if (storedUserType !== null) {
      const parsedUserType = parseInt(storedUserType, 10);
      setUserType(parsedUserType);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewClick = (row: any) => {
    setOpen(true);
    setViewData(row);
    const customerData = customerOptions.find(
      (customer) => customer.id === row.customer_id
    );
    setCutomerData(customerData);
  };
  const columns = [
    { field: "serial_no", headerName: "S.No", width: 150 },
    { field: "invoiceid", headerName: "Invoice ID", width: 175 },
    { field: "invoicedate", headerName: "Invoice Date", width: 150 },
    {
      field: "price",
      headerName: "Invoice Amount",
      width: 175,
      renderCell: (params: any) => {
        const currencySymbol = getCurrencySymbol1(params.row.currency);
        return params.value != null
          ? `${currencySymbol}  ${params.value}`
          : "NA";
      },
    },
    {
      field: "due_amount",
      headerName: "Balance Amount",
      width: 150,
      renderCell: (params: any) => {
        const currencySymbol = getCurrencySymbol1(params.row.currency);
        return params.value !== null && !isNaN(params.value)
          ? `${currencySymbol} ${params.value}`
          : "NA";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 175,
      renderCell: (params: { value: string }) => {
        const status = params.value;
        const { color, Icon } = getStatusColorAndIcon(status);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ color, marginRight: "5px" }} />
            {status}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params: { row: Invoicemock }) => (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "50px",
            cursor: "pointer",
          }}
        >
          <IconButton onClick={() => handleViewClick(params.row)}>
            <VisibilityIcon
              style={{
                width: "24px",
                height: "26px",
                color: "181D8C",
                marginTop: "7px",
                cursor: "pointer",
              }}
            />
          </IconButton>
          <SaveAltIcon
            style={{ color: "#165D59" }}
            onClick={() => handleDownloadPDF(params.row)}
          />
        </div>
      ),
      width: 100,
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.INVOICE}
          </Typography>
          <PheonixBreadcrumbs />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredInvoicesData.length > 0
              ? filteredInvoicesData
              : invoicessData
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          onSearchQueryChange={handleSearchQueryChange}
          searchQuery={searchQuery}
          loading={dataLoading}
        />
      </div>
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={succMessage}
        vertical="top"
        horizontal="center"
      />
      <InvoiceModal
        open={open}
        viewData={viewData}
        onClose={handleClose}
        customerData={customerData}
      />
    </div>
  );
};
export default Invoicescreen;
