import React, { ReactNode } from 'react';
import Header from '../Pages/Header';
import AppBarProps from '../interfaces/AppBarProps';

const AppBar: React.FC<AppBarProps> = ({ title, children, isSidebarOpen }) => {
  const titleMarginLeft = isSidebarOpen ? '260px' : '100px';
return (
    <div style={{ backgroundColor: 'white', height: '48px', padding: '10px', borderBottom: '1px solid #ccc', width: '100%', display: 'flex', alignItems: 'center', position: 'relative', zIndex: 1 }}>
      <h2 style={{ fontSize: '24px', fontWeight: '700', marginLeft: titleMarginLeft,flex: '1' }}></h2>
      <Header />
      {children}
    </div>
  );
};
export default AppBar;