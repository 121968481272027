import React, { ChangeEvent, useEffect, useState } from "react";
import PheonixProductTableProps from "./PheonixProductTableprops";
import { GET_ALL_PRODUCTS } from "../../graphql/query";
import Tooltip from "@mui/material/Tooltip";
import { api } from "../../api";
import { useSidebar } from "../../Pages/SidebarContext";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Button,
} from "@mui/material";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../../const";
interface ProductData {
  [key: string]: any;
}

const initialQuoteData: ProductData = {
  "Product Name": "",
  "Product Plan": "",
  "Billing Cycle": "",
  Tax: "",
  Rate: 0,
  "HSN Number": "",
};

const PheonixProductTable: React.FC<PheonixProductTableProps> = ({
  columns,
  rows,
  view,
  edit,
  handleInputChange,
  fieldErrors,
  setFieldErrors,
  QuoteReq,
}) => {
  const planOptions = ["GST 18%", "Non-Taxable"];
  const newOptions = ["Basic", "Medium", "Premium", "Pro", "Custom"];
  const billOptions = ["Monthly", "Quarterly", "Half yearly", "Yearly"];
  const [isAddingProduct, setIsAddingProduct] = useState<boolean[]>([]);
  const [newProductName, setNewProductName] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [allproductsData, setAllProductsData] =
    useState<ProductData>(initialQuoteData);
  const [productOptionsAPI, setProductOptions] = useState([]);
  const [error, setError] = useState("");
  const { isOpen } = useSidebar();
  const handleAddProduct = (index: any) => {
    setIsAddingProduct((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_PRODUCTS,
        },
        config
      );
      const products = response.data.data.getallproducts;
      setAllProductsData(products);
      const productOptions = products.map((product: any) => product.name);
      setProductOptions(productOptions);
    } catch (error: any) {
      setError(error.message);
    }
  };
  const clearFieldError = (index: number, fieldName: string) => {
    setFieldErrors((prevErrors: { [key: string]: any }) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[index]) {
        delete updatedErrors[index][fieldName];
        if (Object.keys(updatedErrors[index]).length === 0) {
          delete updatedErrors[index];
        }
      }
      return updatedErrors;
    });
  };
  const handleSaveProduct = (
    index: number,
    field: string,
    newProductName: string
  ) => {
    handleInputChange(index, field, newProductName);

    setIsAddingProduct((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
    clearFieldError(index, field);
    setNewProductName("");
  };

  const handleCancelAddProduct = (index: any) => {
    setIsAddingProduct((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
    setNewProductName("");
  };

  const renderCell = (column: any, row: any, index: any) => {
    const productOptions = [
      ...productOptionsAPI.map((product) => ({
        label: product,
        value: product,
      })),
      { label: "Add Product", value: "addProduct" },
    ];

    if (column.render) {
      return column.render(index);
    }

    if (
      column.field === "Rate" ||
      column.field === "Quantity" ||
      column.field === "Amount" ||
      column.field === "Product Details" ||
      column.field === "HSN Number"
    ) {
      if (column.field === "Product Details") {
        if (isAddingProduct[index]) {
          return (
            <div>
              <TextField
                label="Product Name"
                value={newProductName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewProductName(e.target.value)
                }
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                }}
                style={{ width: "160px" }}
              />
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleCancelAddProduct(index)}
                  sx={{
                    color: "#181D8C",
                    textTransform: "none",
                  }}
                >
                  {TEXT_MESSAGES.CANCEL}
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    handleSaveProduct(index, column.field, newProductName)
                  }
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#181D8C",
                    marginLeft: "10px",
                    "&:hover": {
                      backgroundColor: "#181D8C",
                    },
                  }}
                >
                  {TEXT_MESSAGES.SAVE}
                </Button>
              </div>
            </div>
          );
        }
        return (
          <Tooltip title={row[column.field] || "Select an option"}>
            <Autocomplete
              disablePortal
              disableClearable={true}
              options={productOptions}
              size="small"
              disabled={view && !edit}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.label
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  error={!!fieldErrors?.[index]?.[column.field]}
                  helperText={fieldErrors?.[index]?.[column.field] || ""}
                />
              )}
              renderOption={(props, option) => {
                if (option.value === "addProduct") {
                  return (
                    <li {...props} key="add-product">
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          backgroundColor: "#C6C7F8",
                          border: "1px solid #181D8C",
                          color: "#181D8C",
                          "&:hover": {
                            backgroundColor: "#C6C7F8",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddProduct(index);
                        }}
                        fullWidth
                      >
                        {TEXT_MESSAGES.ADD_PRODUCT}
                      </Button>
                    </li>
                  );
                }
                return (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                );
              }}
              onChange={(event, newValue) => {
                if (newValue?.value !== "addProduct") {
                  handleInputChange(index, column.field, newValue.value);
                }
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "15px",
                },
                marginLeft: "-8px",
              }}
              value={row[column.field] || null}
              style={{ width: "170px" }}
            />
          </Tooltip>
        );
      }

      return (
        <TextField
          type="text"
          value={row[column.field] || ""}
          disabled={view && !edit}
          placeholder={
            column.field === "Rate" || column.field === "Amount"
              ? "0.00"
              : column.field === "Quantity"
              ? "0"
              : column.field === "Product Details"
              ? "Enter Product"
              : "Enter HSN"
          }
          size="small"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(index, column.field, e.target.value)
          }
          error={!!fieldErrors?.[index]?.[column.field]}
          helperText={fieldErrors?.[index]?.[column.field] || ""}
          FormHelperTextProps={{
            style: { textAlign: "right" }, 
          }}
          sx={{
            padding: "4px",
            marginLeft: (() => {
              if (
                column.field === "Product Details" ||
                column.field === "Tax"
              ) {
                return "-14%";
              } else if (column.field === "HSN Number") {
                return isOpen ? "-21%" : "-1%";
              } else {
                return "-10%";
              }
            })(),
            marginRight:
              column.field === "Rate"
                ? "-15px"
                : column.field === "Amount"
                ? isOpen
                  ? "0px"
                  : "15px"
                : "0px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
          inputProps={{
            style: {
              textAlign:
                (QuoteReq && column.field === "Product Details") ||
                (QuoteReq && column.field === "Quantity")
                  ? "right"
                  : column.field === "Product Details" ||
                    column.field === "HSN Number" ||
                    column.field === "Quantity"
                  ? "left"
                  : "right",
            },
          }}
          InputProps={{
            style: { textAlign: "right" },
          }}
        />
      );
    }

    if (
      column.field === "Tax" ||
      column.field === "Product Plan" ||
      column.field === "Billing Cycle"
    ) {
      return (
        <Tooltip title={row[column.field] || "Select an option"}>
          <Autocomplete
            disablePortal
            disableClearable={true}
            options={
              column.field === "Tax"
                ? planOptions
                : column.field === "Product Plan"
                ? newOptions
                : billOptions
            }
            size="small"
            disabled={view && !edit}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                error={!!fieldErrors?.[index]?.[column.field]}
                helperText={fieldErrors?.[index]?.[column.field] || ""}
              />
            )}
            onChange={(event, newValue) =>
              handleInputChange(index, column.field, newValue)
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
            }}
            value={row[column.field] || null}
            style={{
              width: "130px",
              marginLeft:
                QuoteReq && column.field === "Product Plan"
                  ? "25%"
                  : column.field === "Product Plan"
                  ? "7%"
                  : column.field === "Tax"
                  ? "-4%"
                  : "10%",
            }}
          />
        </Tooltip>
      );
    }

    return row[column.field];
  };

  return (
    <TableContainer style={{ width: "100%" }}>
      <Table>
        <TableHead>
          <TableRow style={{ fontFamily: "Roboto" }}>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                style={{
                  width:
                    column.field === "Rate"
                      ? "150px"
                      : column.field === "Quantity" || column.field === "Tax"
                      ? "100px"
                      : column.field === "Amount"
                      ? "150px"
                      : column.field === "Billing Cycle" ||
                        column.field === "Product Plan"
                      ? "130px"
                      : column.field === "Product Details" ||
                        column.field === "HSN Number"
                      ? "170px"
                      : "100px",
                  padding: "10px",
                  fontSize: column.field === "HSN Number" ? "15px" : "16px",
                  color: "black",
                  fontFamily: "Roboto",
                  fontWeight: 600,

                  textAlign:
                    column.field === "Product Details" || column.field === "Tax"
                      ? "left"
                      : column.field === "Quantity" || column.field === "Rate"
                      ? "right"
                      : "center",
                }}
              >
                {column.headerName}
                {column.field !== "Action" &&
                  column.field !== "Product Plan" && (
                    <span style={{ color: "red" }}> *</span>
                  )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              style={{
                color: "black",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "16px",
              }}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  style={{
                    width:
                      column.field === "Rate"
                        ? "130px"
                        : column.field === "Quantity" ||
                          column.field === "Tax" ||
                          column.field === "HSN Number"
                        ? "80px"
                        : column.field === "Amount"
                        ? "120px"
                        : column.field === "Billing Cycle" ||
                          column.field === "Product Plan"
                        ? "130px"
                        : column.field === "Product Details"
                        ? "170px"
                        : "100px",
                    fontFamily: "Roboto",
                    color: "black",
                    textAlign:
                      column.field === "Product Details" ? "left" : "center",
                  }}
                >
                  {renderCell(column, row, index)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PheonixProductTable;
