import React, { createContext, useState, useEffect, useContext } from 'react';
import { SessionContextProviderProps } from '../interfaces/ContextInterface';
import SessionContextType from "../interfaces/SessionContextType";
import { TEXT_MESSAGES } from "../const"
const SessionContext = createContext<SessionContextType | undefined>(undefined);
const SessionContextProvider: React.FC<SessionContextProviderProps> = ({ children }) => {
 const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);
  const login = () => {
    localStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
  };
  const logout = () => {
    localStorage.setItem('isLoggedIn', 'false');
    setIsLoggedIn(false);
  };
  return (
    <SessionContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </SessionContext.Provider>
  );
};
const useSession = (): SessionContextType => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error(TEXT_MESSAGES.USE_SESSION);
  }
  return context;
};
export { SessionContextProvider, useSession };
