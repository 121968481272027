import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import DeleteModalProps from "../interfaces/DeleteModalProps";
import { TEXT_MESSAGES } from "../const";
import LogoutIcon from "../assets/logout.png";

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff",
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
          position: "relative",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          height: "180px",
          width: "323px",
          padding: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: "18px",
          padding: "0",
          marginBottom: "10px",
          position: "relative",
        }}
      >
        {TEXT_MESSAGES.LOGOUTMODAL}
        
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.87)",
          letterSpacing: "0.15px",
          fontWeight: 400,
          fontFamily: "Roboto",
          padding: "0",
          marginBottom: "16px",
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
          {TEXT_MESSAGES.LOGOUTSESSION}
        </Typography>
        <img src={LogoutIcon} alt="Delete Icon" style={{ margin: "16px 0" }} />
        
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0",
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"

          sx={{
            color: "#181D8C",
            backgroundColor:"#ffffff",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            border:"2px solid #181D8C",
            borderRadius: "8px",
            marginBottom: "8px",
            marginRight: "8px",
            padding: "6px 16px",
            width: "84px",
            height: "36px",
            "&:hover": {
                backgroundColor: "#FFFFFF", 
                color: "#181D8C", 
                border:"2px solid #181D8C",
              },
          }}
        >
          {TEXT_MESSAGES.CANCEL}
        </Button>
        <Button
          onClick={onConfirm}
          
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#181D8C",
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            borderRadius: "8px",
            marginBottom: "8px",
            marginRight: "8px",
            padding: "6px 16px",
            width: "84px",
            height: "36px",
            "&:hover": {
              backgroundColor: "#181D8C", 
              color: "#FFFFFF", 
            },
          }}
        >
          {TEXT_MESSAGES.LOGOUT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
