import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { api } from '../api';
import { ERROR_MESSAGES, TEXT_MESSAGES } from '../const';

const Confirmation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [confirm, setConfirm] = useState(false)
  const confirmEmail = async (token: string) => {
    try {
      const response = await axios.post(api.baseUrl, {
        query: `
          mutation ConfirmEmail($token: String!) {
            confirmEmail(token: $token) {
              success
              message
            }
          }
        `,
        variables: {
          token: token,
        },
      });

      if (response.data && response.data.data.confirmEmail.success) {
  
        setConfirm(true)
        setTimeout(() => {
          window.close();
        }, 3000);
      } else {
        console.error(ERROR_MESSAGES.EMAILCONFIRM_FAILED, response.data.data.confirmEmail.message);
      }
    } catch (error) {
      console.error(ERROR_MESSAGES.CONFIRMATION_ERROR, error);
    }
  };
  useEffect(() => {
    const confirmationLink = window.location.href;
    const extractTokenFromLink = (link: any) => {
      const parts = link.split('/');
      const token = parts[parts.length - 1];
      return token;
    };
    const token = extractTokenFromLink(confirmationLink);
    if (token) {
      confirmEmail(token);
    }
  }, [location.search, confirmEmail]);
  return (
    <div>
      <h2>{TEXT_MESSAGES.EMAIL_CONFIRM}</h2>
      {confirm ? (
        <p>{TEXT_MESSAGES.EMAIL_VERIFIED}</p>
      ) : (
        <p>{TEXT_MESSAGES.SORRY}</p>
      )}
    </div>
  );
};
export default Confirmation;