import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import PheonixButton from '../Components/PheonixButton';
import { serviceplans,TEXT_MESSAGES } from '../const';

const Plans: React.FC = () => {

    const services = serviceplans;
    const handlePlanClick = () => {

    };

    return (
        <div style={{ marginLeft: '45px', marginRight: '50px' }}>
            <h2 style={{ fontSize: '22px', fontWeight: 700 }}>{TEXT_MESSAGES.SERVICES}</h2>
            <Grid container spacing={2}>
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={service.id}>
                        <Card sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                                    <Typography component="h3" variant="h6" style={{ fontWeight: 700, marginBottom: '8px' }}>
                                        {service.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{ color: '#2196F3', marginTop: "10px", marginRight: "20px", fontSize: "16px", cursor: "pointer" }}>
                                        {TEXT_MESSAGES.MOREDETAILS}
                                    </Typography>
                                </div>
                                <ul style={{ paddingLeft: '20px', marginBottom: '8px' }}>
                                    {service.content.map((item, index) => (
                                        <li key={index}>
                                            <Typography variant="body2" color="textSecondary">
                                                {item}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
                                    <PheonixButton
                                        label="Add To Cart"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={handlePlanClick}
                                        sx={{ marginTop: "5px", border: "1px solid #000", backgroundColor: "transparent !important", height: "20px !important", width: "120px !important", fontWeight: '500', fontSize: '14px', color: "#032C43 !important" }}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default Plans;
