import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PheonixBreadcrumbsProps from "../interfaces/PheonixBreadcrumbsProps";

const PheonixBreadcrumbs: React.FC<PheonixBreadcrumbsProps> = ({
  breadcrumbText,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        underline="hover"
        color="inherit"
        onClick={() => navigate("/home")}
        sx={{ display: "flex", alignItems: "center" }}
      >
        Dashboard
      </Link>

      {pathnames.map((value, index) => {
        let to:any;

        if (value.toLowerCase().startsWith("quotation")) {
          to = "/quotation";
        } else if (value.toLowerCase().startsWith("invoice")) {
          to = "/invoice";
        } else if (value.toLowerCase().startsWith("order")) {
          to = "/order";
        } else {
          to = `/${pathnames.slice(0, index + 1).join("/")}`;
        }

        let displayValue;
        if (value.toLowerCase().startsWith("quotation")) {
          displayValue = "Quotation";
        } else if (value.toLowerCase().startsWith("invoice")) {
          displayValue = "Invoice";
        } else if (value.toLowerCase().startsWith("order")) {
          displayValue = "Order";
        } else {
          displayValue = value.charAt(0).toUpperCase() + value.slice(1);
        }

        return (
          <Link
            key={to}
            underline="hover"
            color="inherit"
            onClick={() => navigate(to)}
            sx={{ display: "flex", alignItems: "center",cursor:'pointer' }}
          >
            {displayValue}
          </Link>
        );
      })}

      {breadcrumbText && (
        <Typography
          color="text.primary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          {breadcrumbText}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default PheonixBreadcrumbs;
