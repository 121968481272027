import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  IconButton,
  AppBar,
  Typography,
  Modal,
  Stack,
  styled,
  Button,
  Container,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import PheonixCustomerModalProps from "../interfaces/PheonixCustomerModalProps";
import PheonixTextField from "./PheonixTextField";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";

const Item = styled("div")({
  marginBottom: "15px",
  padding: "8px",
  textAlign: "center",
});

const PheonixCustomerModal = <T extends object>({
  open,
  view,
  onClose,
  onConfirm,
  fields,
  formData,
  setFormData,
  handleConfirm,
  switchSelected,
  setSwitchSelected,
  userSelected,
  setUserSelected,
}: PheonixCustomerModalProps<T>) => {
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    setFieldErrors({});
    setEdit(false);
  }, [open]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setIsConfirmDisabled(hasErrors);
  }, [fieldErrors]);

  const validateForm = () => {
    let isAnyFieldEmpty = false;
    fields.forEach((field) => {
      const fieldValue = (formData as { [key: string]: string })[
        "Contact Email ID"
      ];
      if (!fieldValue) {
        isAnyFieldEmpty = true;
      } else {
        validateField(field.label);
      }
    });
    setIsButtonDisabled(isAnyFieldEmpty);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSwitchSelected(isChecked);
  };
  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setUserSelected(isChecked);
  };

  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
  };

  const handleFieldBlur = (fieldLabel: string) => {
    validateField(fieldLabel);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const validateField = (fieldLabel: string) => {
    const fieldValue = (formData as { [key: string]: string })[fieldLabel];
    switch (fieldLabel) {
      case "Contact Number":
        if (fieldValue && !/^[0-9]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NUMBER_ERROR,
          }));
        } else if (fieldValue.length !== 0 && fieldValue.length !== 10) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NUMBER_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Pincode":
        if (fieldValue && !/^[0-9]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.PINCODE_ERROR,
          }));
        }
        break;
      case "Contact Email ID":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_REQ,
          }));
        } else if (!TEXT_MESSAGES.EMAIL_REG.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;

      case "Customer Full Name":
        if (!/^[a-zA-Z\s]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NAME_VALID,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Country":
        if (!/^[a-zA-Z\s]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.COUNTRY_VALID,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "City":
        if (!/^[a-zA-Z\s]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.CITY_VALID,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "State":
        if (!/^[a-zA-Z\s]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.STATE_VALID,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "GST Number":
        if (fieldValue && !/^[a-zA-Z0-9]+$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.GST_ERR1,
          }));
        } else if (fieldValue.length !== 0 && fieldValue.length !== 15) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.GST_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100vh",
          width: 425,
          boxShadow: 24,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5%",
          }}
        >
          <div
            style={{
              width: "38px",
              height: "38px",
              backgroundColor: "#EFEFFF",
              borderRadius: "15px",
              display: "flex",
              alignSelf: "flex-start",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "5%",
            }}
          >
            <PersonAddAltOutlinedIcon style={{ color: "#18308c" }} />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "2%" }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Roboto",
              }}
            >
              {isEdit
                ? TEXT_MESSAGES.EDIT_Customer
                : view
                ? TEXT_MESSAGES.VIEW_CUSTOMER
                : TEXT_MESSAGES.ADD_CUSTOMER}
            </Typography>
          </div>
          <IconButton
            onClick={onClose}
            style={{
              position: "absolute",
              right: "15px",
              width: "16px",
              height: "16px",
              padding: "5px",
              color: "#5B616B",
              marginTop: "2%",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#CBC2E9",
            marginTop: "5%",
          }}
        ></div>
        <div style={{ maxHeight: "600px", overflowY: "auto" }}>
          {view && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "10px",
                marginRight: "8px",
              }}
            >
              <Button
                onClick={handleEdit}
                disabled={isEdit}
                sx={{
                  textTransform: "none",
                  color: "white",
                  border: "1px solid",
                  backgroundColor: isEdit ? "lightgray" : "#181D8C",
                  width: "75px",
                  height: "25px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                <EditIcon
                  sx={{
                    marginRight: "5px",
                    marginLeft: 0,
                    height: "16px",
                    width: "18px",
                  }}
                />
                {TEXT_MESSAGES.EDIT}
              </Button>
            </div>
          )}
          <Stack spacing={1} direction="column">
            {fields.map((field, index) => (
              <Item key={index}>
                <TextField
                  id="outlined-error"
                  type={field.label === "Address" ? "textarea" : "text"}
                  placeholder={field.label}
                  label={
                    <>
                      <span style={{ color: "#181D8C" }}>{field.label}</span>
                      {field.label === "Contact Email ID" && (
                        <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                      )}
                    </>
                  }
                  value={(formData as { [key: string]: string })[field.label]}
                  error={!!fieldErrors[field.label]}
                  helperText={fieldErrors[field.label]}
                  onBlur={() => handleFieldBlur(field.label)}
                  onChange={(e) =>
                    handleFieldChange(field.label, e.target.value)
                  }
                  variant="outlined"
                  style={{ width: "360px" }}
                  multiline={field.label === "Address"}
                  rows={field.label === "Address" ? 2 : 1}
                  InputProps={{
                    readOnly: view && !isEdit,
                  }}
                />
              </Item>
            ))}
          </Stack>

          <FormGroup
            aria-label="position"
            row
            style={{ marginLeft: "2%", marginTop: "10%" }}
          >
            <FormControlLabel
              value="end"
              control={<Switch color="primary" onChange={handleSwitchChange} />}
              label= {isEdit? "Send Email for Password Update" :"Send welcome invite to customer"}
              labelPlacement="end"
              style={{ marginLeft: "0%", marginTop: "-8%" }}
              checked={switchSelected}
              disabled={!isEdit && view}
            />
          </FormGroup>
          
        </div>
          <div
            style={{ height: "2px", width: "100%", backgroundColor: "#CBC2E9" , marginTop:"10px"}}
          ></div>
          <div
          style={{
            display: "flex",
            width: "100%",
            alignSelf: "flex-end",
            marginTop: "auto",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              marginBottom: "15px"
            }}
          >
            <Button
              onClick={onClose}
              style={{
                textTransform: "none",
                color: "#181D8C",
                backgroundColor: "white",
                border: "1px solid #181D8C",
                width: "125px",
                height: "36px",
                borderRadius: "5px",
                marginLeft: "2%",
              }}
            >
              {TEXT_MESSAGES.CANCEL}
            </Button>
            {view && isEdit ? (
              <Button
                disabled={isButtonDisabled || isConfirmDisabled}
                onClick={handleConfirm}
                sx={{
                  textTransform: "none",
                  color: "white",
                  border: "1px solid",
                  backgroundColor:
                    isButtonDisabled || isConfirmDisabled
                      ? "lightgray"
                      : "#181D8C",
                  width: "125px",
                  height: "36px",
                  borderRadius: "5px",
                  marginRight: "2%",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                {TEXT_MESSAGES.UPDATE_TXT}
              </Button>
            ) : !view ? (
              <Button
                disabled={isButtonDisabled || isConfirmDisabled}
                onClick={handleConfirm}
                sx={{
                  textTransform: "none",
                  color: "white",
                  border: "1px solid",
                  backgroundColor:
                    isButtonDisabled || isConfirmDisabled
                      ? "lightgray"
                      : "#181D8C",
                  width: "125px",
                  height: "36px",
                  borderRadius: "5px",
                  marginRight: "2%",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                {TEXT_MESSAGES.SAVE}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PheonixCustomerModal;
