import React, { ChangeEvent, FocusEvent, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PheonixTextField from '../../Components/PheonixTextField';
import SubmitButton from '../../Components/PheonixButton';
import SnackBar from '../../Components/PheonixSnackBar';
import logo from '../../assets/logo.jpg';
import { api } from '../../api';
import axios from 'axios';
import { ERROR_MESSAGES, TEXT_MESSAGES } from '../../const';
import PheonixPaper from '../../Components/PheonixPaper';
import PheonixBox from '../../Components/PheonixBox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, styled } from '@mui/material';
import PheonixContainer from '../../Components/PheonixContainer';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSignUpContext } from '../../Contexts/SignUpContext';
const Item = styled('div')({
  marginBottom: '10px',
  padding: '8px',
  textAlign: 'center',
});

const Signup: React.FC = () => {
  const [email, setemail] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [password, setpassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessage1, setErrorMessage1] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');
  const [fnameerrorMessage, setFnameErrorMessage] = useState('');
  const [lnameerrorMessage, setLnameErrorMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState('')
  const [buttonDisable, setButtonDisable] = useState(true);
  const { showSignButton, setShowSignButton } = useSignUpContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
    );
  
  useEffect(() => {
    const cameFromLogin = sessionStorage.getItem('cameFromLogin');
    if (!cameFromLogin) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    sessionStorage.removeItem('cameFromLogin');
  }, []);
  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handlepasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpassword(e.target.value);
  };
  const handlefirstnameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const validName = newName.replace(/[^a-zA-Z]/g, '');
    setfirstname(validName);
  };
  const handlelastnameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const validName = newName.replace(/[^a-zA-Z]/g, '');
    setlastname(validName);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  function isValidEmail(email: string) {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    return emailRegex.test(email);
  }
  const handleEmailFocus = () => {
    setErrorMessage('');
  };
  const handlefnameFocus = () => {
    setFnameErrorMessage('');
  };
  const handlelnameFocus = () => {
    setLnameErrorMessage('');
  };
  const handlePasswordFocus = () => {
    setErrorMessage1('');
    setErrorMessage2('');
  };
  const handleEmailBlur = () => {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage('');
    }
  };
  const handlefnameBlur = () => {
    if(!firstname.length){
      setFnameErrorMessage(ERROR_MESSAGES.FNAME_ERROR)
    }
  };
  const handlelnameBlur = () => {
    if(!lastname.length){
      setLnameErrorMessage(ERROR_MESSAGES.LNAME_ERROR)
    }
  };
  const handlePasswordBlur = () => {
    const minLength = 8;
    const passlength = password.length >= minLength;
    if(password.length===0){
      setErrorMessage1(ERROR_MESSAGES.VALID_PASSWORD);
      }
   else if (!passlength) {
      setErrorMessage1(ERROR_MESSAGES.PWD_LENGTH);
    } else {
      setErrorMessage1('');
    }
  }
  useEffect(() => {
    const emailValid = isValidEmail(email);
    const passwordValid = isValidPassword(password);
    if (firstname && lastname && emailValid && passwordValid) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }, [firstname, lastname, email, password]);
  const isValidPassword = (password: string): boolean => {
    const minLength = 8;
    return password.length >= minLength;
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleSignUp();
    }
  };

  const handleSignUp = async () => {
    if (!email || !password || !firstname || !lastname) {
      setErrorMessage1(ERROR_MESSAGES.ENTER_ALL);
      return;
    }
    if (!isValidEmail(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
      return;
    }
    if (password.length < 8) {
      setErrorMessage2(ERROR_MESSAGES.PWD_LENGTH);
      return;
    }
    const query = `
    mutation registerUser($email : String!, $firstname : String!, $lastname : String!, $password: String! ){
        registerUser(email : $email, firstname: $firstname, lastname: $lastname, password : $password){
          success
          message
      }
    }
    `;
    const variables = {
      email: email,
      firstname: firstname,
      lastname: lastname,
      password: password
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const registerUserData = response.data.data?.registerUser;
      if (registerUserData && registerUserData.success) {
        setShowSignButton(true)
        setSuccMessage(registerUserData.message)
        setSnackbarSeverity('success')
        setModalOpen(true);
        setTimeout(() => {
          navigate('/')
        }, 3000);
      }
      else {
        setSuccMessage(registerUserData.message);
        setSnackbarSeverity('error')
        setModalOpen(true);
        setTimeout(() => {
          setModalOpen(false);
          setSuccMessage('');
        }, 3000);
      }
      setVerificationMessage(ERROR_MESSAGES.CHECK_MAIL);
    }
    catch (error) {
      console.error(ERROR_MESSAGES.ERROR, error);
    }
  };
  const handleClose = () => {
    setModalOpen(false);
    navigate('/');
  };
  const customCloseIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} style={{ justifyContent: 'end', width: '200px' }}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <div style={{ backgroundColor: "#F7F7F7",marginTop:'20px' }}>
      <PheonixBox >
        <PheonixContainer maxWidth='xs'>
          <PheonixPaper
            elevation={3}
            style={{
              padding: '20px',
              height: errorMessage||errorMessage1||errorMessage2||fnameerrorMessage||lnameerrorMessage ? '700px':'600px',
              marginLeft: '10px',
              width: '400PX',
              borderRadius: '0',
            }}
          >
            <Stack spacing={3} direction='column'>
              <Item>
                <img src={logo} alt="Your Alt Text" style={{ width: '150px', height: '99px' }} />
              </Item>
              <Box component="form" onKeyPress={handleKeyPress}>
                <Item>
                  <Typography variant="h6" style={{ color: '#1161A0', fontFamily: 'Roboto', marginTop: '-15px' }}>{TEXT_MESSAGES.SIGN_LABEL}</Typography>
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="text"
                    value={firstname}
                    onChange={handlefirstnameChange}
                    onFocus={handlefnameFocus}
                    onBlur={handlefnameBlur}
                    label="First Name"
                    variant="outlined"
                    style={{ width: "350px", }}
                    error={Boolean(fnameerrorMessage)}
                    helperText={fnameerrorMessage || ''}
                  />
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="text"
                    value={lastname}
                    onChange={handlelastnameChange}
                    onFocus={handlelnameFocus}
                    onBlur={handlelnameBlur}
                    label="Last Name"
                    variant="outlined"
                    style={{ width: "350px", }}
                    error={Boolean(lnameerrorMessage)}
                    helperText={lnameerrorMessage || ''}
                    disabled={Boolean(fnameerrorMessage)} 

                  />
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="email"
                    value={email}
                    label="Email"
                    variant="outlined"
                    onChange={handleemailChange}
                    onFocus={handleEmailFocus}
                    onBlur={handleEmailBlur}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage || ''}
                    style={{ width: "350px" }}
                    disabled={Boolean(lnameerrorMessage || fnameerrorMessage)} 
                  />
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    label="Password"
                    variant="outlined"
                    onChange={handlepasswordChange}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    error={Boolean(errorMessage1)}
                    helperText={errorMessage1 || ''}
                    style={{ width: "350px" }}
                    endAdornment={
                      <InputAdornment position="end" style={{ cursor: 'pointer', marginTop: '0px',color:'black' }} onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </InputAdornment>
                    }
                    disabled={Boolean(fnameerrorMessage || lnameerrorMessage || errorMessage)} 
                  />
                </Item>
                <Item>
                  <SubmitButton label={TEXT_MESSAGES.SIGN_TEXT} onClick={handleSignUp} disabled={buttonDisable} />
                </Item>
              </Box>
            </Stack>
          </PheonixPaper>
          <SnackBar
            open={modalOpen}
            onClose={handleClose}
            severity={snackbarSeverity}
            message={succMessage}
            vertical="top"
            horizontal="center"
            customAction={customCloseIcon}
          />
        </PheonixContainer>
      </PheonixBox>
    </div>
  );
};
export default Signup;