import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import productImage from '../assets/product.png';
import PheonixButton from '../Components/PheonixButton';
import { useNavigate } from 'react-router-dom';
import { productsdata, servicesdata ,TEXT_MESSAGES} from '../const';
const ProductList: React.FC = () => {
    const navigate = useNavigate();

    const products = productsdata;
    const services = servicesdata;

    const handleplanClick = () => {
        navigate('/plans')
    }

    return (
        <div style={{ marginLeft: '45px', marginRight: '50px' }}>
            <h2 style={{ fontSize: '22px', fontWeight: 700 }}>{TEXT_MESSAGES.PRODUCTS}</h2>
            <Grid container spacing={1} >
                {products.map(product => (
                    <Grid item xs={12} sm={4} key={product.id}>
                        <Card sx={{ display: 'flex', width: 350, height: 80, borderRadius: '10px' }}>
                            <CardMedia
                                component="img"
                                height="50px"
                                style={{ width: '50px', objectFit: 'cover', margin: '15px 0px 0px 20px' }}
                                image={product.imageUrl}
                                alt={product.title}
                            />
                            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography component="div" style={{ fontWeight: 700, fontSize: '16px' }}>
                                        {product.title}
                                    </Typography>
                                    <PheonixButton
                                        label="VIEW PLANS"
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        onClick={handleplanClick}
                                        sx={{ marginTop: "5px", backgroundColor: "transparent !important", height: "24px !important", width: "90px !important", fontWeight: '500', fontSize: '12px', color: "#032C43 !important" }}
                                    />
                                </div>
                                <Typography variant="body2" color="textSecondary" component="p" style={{ color: '#2196F3', marginTop: "0px", cursor: "pointer" }}>
                                  {TEXT_MESSAGES.PRODUCT_SITE}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <h2 style={{ fontSize: '22px', fontWeight: 700 }}>Services</h2>
            <Grid container spacing={1} >
                {services.map(service => (
                    <Grid item xs={12} sm={4} key={service.id}>
                        <Card sx={{ display: 'flex', width: 350, height: 80, borderRadius: '10px' }}>
                            <CardMedia
                                component="img"
                                height="50px"
                                style={{ width: '50px', objectFit: 'cover', margin: '15px 0px 0px 20px' }}
                                image={service.imageUrl}
                                alt={service.title}
                            />
                            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography component="div" style={{ fontWeight: 700, fontSize: '16px' }}>
                                        {service.title}
                                    </Typography>
                                    <PheonixButton
                                        label="VIEW PLANS"
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        onClick={handleplanClick}
                                        sx={{ marginTop: "5px", backgroundColor: "transparent !important", height: "24px !important", width: "90px !important", fontWeight: '500', fontSize: '12px', color: "black !important" }}
                                    />
                                </div>
                                <Typography variant="body2" color="textSecondary" component="p" style={{ color: '#2196F3', marginTop: "0px", cursor: "pointer" }}>
                                    Take me to Service Site
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default ProductList;
