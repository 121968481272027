import React, { useState, useEffect } from "react";
import { Box, Typography, Modal } from "@mui/material";
import { InvoiceCustomerModalProps } from "./InvoiceInterface";
import { ERROR_MESSAGES, TEXT_MESSAGES, pdfdata } from "../../const";
import logo from "../../assets/logo-quotes.png";

const InvoiceModal: React.FC<InvoiceCustomerModalProps> = ({
  open,
  view,
  viewData,
  onClose,
  customerData,
}) => {

  const subtotal = viewData.productOrService?.reduce(
    (acc: number, product: any) => acc + product.amount,
    0
  );
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100vh",
          width: 588,
          boxShadow: 24,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "5px",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Invoice"
            style={{
              width: "165px",
              height: "109px",
              objectFit: "cover",
              marginTop: "0px",
              marginLeft: "10px",
            }}
          />
          <div style={{ textAlign: "right" }}>
            <h2
              style={{
                textAlign: "right",
                marginRight: "25px",
                marginTop: "0px",
                fontSize: "40px",
                marginBottom: "0px",
              }}
            >
              {TEXT_MESSAGES.INVOICE_TXT}
            </h2>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <table style={{ margin: "10px" }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {TEXT_MESSAGES.INVOICE_ID}:
                    </td>
                    <td
                      style={{
                        color: "black",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {viewData.invoiceid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {TEXT_MESSAGES.INVOICE_DATE}:
                    </td>
                    <td
                      style={{
                        color: "black",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {viewData.invoicedate}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {TEXT_MESSAGES.VALID_TILL}:
                    </td>
                    <td
                      style={{
                        color: "black",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {viewData.duedate}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div
            style={{ fontSize: "16px", fontWeight: "600", marginLeft: "15px" }}
          >
            <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {TEXT_MESSAGES.MODAL_FROM}
            </p>
            {pdfdata.PHEONIX_ADDRESS.split("\n").map((line, index) => (
              <p
                key={index}
                style={{
                  margin: "2px 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {line}
              </p>
            ))}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "10px",
              textAlign: "right",
            }}
          >
            <p
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: "5px 10px",
                marginBottom: "15px",
                textAlign: "right",
                marginRight: "22px",
              }}
            >
              {TEXT_MESSAGES.MODAL_TO}
            </p>
            {customerData?.name && (
              <>
                <p
                  style={{
                    margin: "2px 10px",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {customerData?.name}
                </p>
              </>
            )}

            {customerData?.address && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {customerData?.address}
              </p>
            )}

            {customerData?.email && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {customerData?.email}
              </p>
            )}

            {customerData?.number && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {customerData?.number}
              </p>
            )}

            {customerData?.gst_no && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {customerData?.gst_no}
              </p>
            )}

            {customerData?.city && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {customerData?.city}
              </p>
            )}

            {customerData?.state && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {customerData?.state}
              </p>
            )}

            {customerData?.country && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {customerData?.country}
              </p>
            )}

            {customerData?.pincode && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {customerData?.pincode}
              </p>
            )}
          </div>
        </div>
        <table
          style={{
            width: "100%",
            marginTop: "0px",
            marginRight: "20px",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.PRODUCT_OR_SERVICE}
              </th>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.PDT_TXT}
              </th>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.QUAN}
              </th>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.PRICE}
              </th>
            </tr>
          </thead>
          <tbody>
            {viewData.productOrService?.map((product: any, index: any) => (
              <tr key={index} style={{ padding: "10px" }}>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {product.name}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {product.productplan}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {product.quantity}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {viewData.currency === "Rupees"
                    ? "₹"
                    : viewData.currency === "Dollars"
                    ? "$"
                    : viewData.currency === "Euro"
                    ? "€"
                    : ""}
                  {product.amount}
                </td>
              </tr>
            ))}
            {viewData.currency === "Rupees" ? (
              customerData?.gst_no ? (
                customerData.gst_no.startsWith("33") ? (
                  <>
                   { subtotal > 0 &&( <tr>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                        <Typography sx={{ marginLeft: "5%" }}>
                          {TEXT_MESSAGES.SUBTOTAL}
                        </Typography>
                      </td>
                      <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                      {viewData.currency === "Rupees"
                            ? "₹"
                            : viewData.currency === "Dollars"
                            ? "$"
                            : viewData.currency === "Euro"
                            ? "€"
                            : ""}{subtotal}
                      </td>
                    </tr>)}
                    {viewData?.taxamount !== null && viewData.taxamount !== undefined && viewData.taxamount > 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.CTAX}
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left", padding: "5px",lineHeight:1}}>
                          {viewData.currency === "Rupees"
                            ? "₹"
                            : viewData.currency === "Dollars"
                            ? "$"
                            : viewData.currency === "Euro"
                            ? "€"
                            : ""}
                          {(viewData.taxamount / 2).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {viewData?.taxamount !== null && viewData.taxamount !== undefined && viewData.taxamount > 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.STAX}
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                          {viewData.currency === "Rupees"
                            ? "₹"
                            : viewData.currency === "Dollars"
                            ? "$"
                            : viewData.currency === "Euro"
                            ? "€"
                            : ""}
                          {(viewData.taxamount / 2).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {(viewData.discount && viewData.discount > 0 && viewData.discount !== null) && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.DISCOUNT}
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                          {viewData.discountType === "%" ? (
                            `${viewData.discount}%`
                          ) : (
                            <>
                              {viewData.currency === "Rupees"
                                ? "₹"
                                : viewData.currency === "Dollars"
                                ? "$"
                                : viewData.currency === "Euro"
                                ? "€"
                                : ""}
                              {viewData.discount}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <>
                    <tr>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                        <Typography sx={{ marginLeft: "5%" }}>
                          {TEXT_MESSAGES.SUBTOTAL}
                        </Typography>
                      </td>
                      <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                        ₹{subtotal}
                      </td>
                    </tr>
                    {viewData?.taxamount !== null && viewData.taxamount !== undefined && viewData.taxamount > 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.ITAX}
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                          {viewData.currency === "Rupees"
                            ? "₹"
                            : viewData.currency === "Dollars"
                            ? "$"
                            : viewData.currency === "Euro"
                            ? "€"
                            : ""}
                          {viewData.taxamount}
                        </td>
                      </tr>
                    )}
                    {(viewData.discount && viewData.discount > 0 && viewData.discount !== null) && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.DISCOUNT}
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                          {viewData.discountType === "%" ? (
                            `${viewData.discount}%`
                          ) : (
                            <>
                              {viewData.currency === "Rupees"
                                ? "₹"
                                : viewData.currency === "Dollars"
                                ? "$"
                                : viewData.currency === "Euro"
                                ? "€"
                                : ""}
                              {viewData.discount}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </>
                )
              ) : (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                      <Typography sx={{ marginLeft: "5%" }}>
                        {TEXT_MESSAGES.SUBTOTAL}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                      {viewData.currency === "Rupees"
                        ? "₹"
                        : viewData.currency === "Dollars"
                        ? "$"
                        : viewData.currency === "Euro"
                        ? "€"
                        : ""}
                      {subtotal}
                    </td>
                  </tr>
                  {viewData?.taxamount !== null && viewData.taxamount !== undefined && viewData.taxamount > 0 && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                        <Typography sx={{ marginLeft: "5%" }}>
                          {TEXT_MESSAGES.CTAX}
                        </Typography>
                      </td>
                      <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                        {viewData.currency === "Rupees"
                          ? "₹"
                          : viewData.currency === "Dollars"
                          ? "$"
                          : viewData.currency === "Euro"
                          ? "€"
                          : ""}
                        {(viewData.taxamount / 2).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {viewData?.taxamount !== null && viewData.taxamount !== undefined && viewData.taxamount > 0 && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                        <Typography sx={{ marginLeft: "5%" }}>
                          {TEXT_MESSAGES.STAX}
                        </Typography>
                      </td>
                      <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                        {viewData.currency === "Rupees"
                          ? "₹"
                          : viewData.currency === "Dollars"
                          ? "$"
                          : viewData.currency === "Euro"
                          ? "€"
                          : ""}
                        {(viewData.taxamount / 2).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {(viewData.discount && viewData.discount > 0 && viewData.discount !== null) && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                        <Typography sx={{ marginLeft: "5%" }}>
                          {TEXT_MESSAGES.DISCOUNT}
                        </Typography>
                      </td>
                      <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                        {viewData.discountType === "%" ? (
                          `${viewData.discount}%`
                        ) : (
                          <>
                            {viewData.currency === "Rupees"
                              ? "₹"
                              : viewData.currency === "Dollars"
                              ? "$"
                              : viewData.currency === "Euro"
                              ? "€"
                              : ""}
                            {viewData.discount}
                          </>
                        )}
                      </td>
                    </tr>
                  )}
                </>
              )
            ) : (
              <>
                { subtotal > 0 &&(<tr>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                    <Typography sx={{ marginLeft: "5%" }}>
                      {TEXT_MESSAGES.SUBTOTAL}
                    </Typography>
                  </td>
                  <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                    {viewData.currency === "Rupees"
                      ? "₹"
                      : viewData.currency === "Dollars"
                      ? "$"
                      : viewData.currency === "Euro"
                      ? "€"
                      : ""}
                    {subtotal}
                  </td>
                </tr>)}
                {(viewData.discount && viewData.discount > 0 && viewData.discount !== null) && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right", padding: "5px",lineHeight:1 }}>
                      <Typography sx={{ marginLeft: "5%" }}>
                        {TEXT_MESSAGES.DISCOUNT}
                      </Typography>
                    </td>
                    <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                      {viewData.discountType === "%" ? (
                        `${viewData.discount}%`
                      ) : (
                        <>
                          {viewData.currency === "Rupees"
                            ? "₹"
                            : viewData.currency === "Dollars"
                            ? "$"
                            : viewData.currency === "Euro"
                            ? "€"
                            : ""}
                          {viewData.discount}
                        </>
                      )}
                    </td>
                  </tr>
                )}
              </>
            )}

            <tr>
              <td></td>
              <td></td>
              <td
                style={{
                  textAlign: "right",
                  padding: "5px",lineHeight:1
                }}
              >
                <Typography sx={{ marginLeft: "5%" }}>
                  {TEXT_MESSAGES.TOTAL}
                </Typography>
              </td>
              <td style={{ textAlign: "left", padding: "5px",lineHeight:1 }}>
                {viewData.currency === "Rupees"
                  ? "₹"
                  : viewData.currency === "Dollars"
                  ? "$"
                  : viewData.currency === "Euro"
                  ? "€"
                  : ""}
                {viewData.price}
              </td>
            </tr>

            {viewData.customernotes && (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography>{TEXT_MESSAGES.CUSTOM_NOTES}</Typography>
                  <Typography>{viewData.customernotes}</Typography>
                </div>
              </>
            )}
            {viewData.terms_conditions && (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography>{TEXT_MESSAGES.QUOTE_TERM}</Typography>
                  <Typography>{viewData.terms_conditions}</Typography>
                </div>
              </>
            )}
          </tbody>
        </table>
      </Box>
    </Modal>
  );
};

export default InvoiceModal;
